<template>
  <!--下拉選單-->

  <div class="navbar-collapse collapse navbar-collapse-center">
    <!-- 渲染 route 的位置 -->
    <ul class="nav navbar-nav navbar-center">
      <li>
        <router-link to="about" style="text-decoration: none" target="_top"
          >協會介紹</router-link
        >
      </li>
      <li>
        <router-link to="news" style="text-decoration: none" target="_top"
          >消息公告</router-link
        >
      </li>
      <li>
        <router-link to="farm" style="text-decoration: none" target="_top"
          >慈善貓農場</router-link
        >
      </li>
      <li>
        <router-link to="hospital" style="text-decoration: none" target="_top"
          >非營利慈善貓醫院</router-link
        >
      </li>
      <li><a href="https://crazycat.waca.ec/" target="_blank">貓咪商品義賣</a></li>
      <li>
        <router-link to="help" style="text-decoration: none" target="_top"
          >我要幫助貓咪</router-link
        >
      </li>
    </ul>
  </div>
  <!--下拉選單-->
</template>

<script>
export default {
  name: "MyNavs",
};
</script>

<style></style>
