<template>
  <header>
    <div id="h_color"></div>
    <div id="logo_div">
      <div id="menu_icon"><a href="#">&nbsp;</a></div>
      <div id="logo_svg">
        <a href="/"><img src="../style/CLOGO.png" width="372" height="110" /></a>
      </div>
      <div id="logo_lang">
        <!--<div>ENGLISH</div>-->
      </div>
      <div id="logo_fb">
        <a href="https://www.facebook.com/crazycatcharity2" target="_blank"
          ><img src="../style/fb_icon.png"
        /></a>
      </div>
    </div>
    <!--導覽列-->
    <div class="navbar navbar-default" role="navigation">
      <!--網站標題-->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle"
          data-toggle="collapse"
          data-target=".navbar-collapse"
        >
          <span class="sr-only"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!--網站標題-->

      <MyNavs />
    </div>
  </header>
</template>

<script>
import MyNavs from "./Navs";
export default {
  name: "MyHeader",
  components: {
    MyNavs,
  },
};
</script>

<style>
/**   main      ***/
main {
  width: 1281px;
  margin: 0 auto 0;
}

#main_title {
  background-image: url(../style/title_bottom.png);
  margin: 0 auto 0;
  width: 100%;
  height: 187px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-color: #51be9f;
}
#main_title_img {
  margin: 0 auto 0;
  width: 220px;
}
#main_title_img img {
  margin-top: 28px;
}

.title_text {
  font-size: 26px;
  width: fit-content;
  width: -moz-fit-content;
  margin: 0 auto 0;
  padding-top: 40px;
  padding-bottom: 45px;
}
.bg_color {
  padding-top: 0;
  margin-top: 8px;
  background-color: #bbb;
  height: 2px;
}
.f_bg_color {
  width: 40px;
  background-color: #51be9f;
  height: 2px;
}

#i_body {
  color: #4e4e4e;
  background-color: #f5f5f5;
  width: 1100px;
  margin: 0 auto 0;
  position: relative;
}

#body_text_div {
  padding: 65px;
  line-height: 35px;
  text-align: justify;
  padding-left: 70px;
  height: 580px;
  letter-spacing: 2px;
  font-family: "sans-serif", "Microsoft JhengHei";
}
#body_text2_div {
  width: 1000px;
  padding-left: 70px;
  font-size: 11px;
  line-height: 25px;
  letter-spacing: 2px;
  padding-bottom: 50px;
  font-family: "sans-serif", "Microsoft JhengHei";
}
#body_img_div {
  position: absolute;
  float: right;
  top: 10px;
  right: 10px;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.color_div {
  position: absolute;
  top: 60px;
  right: 50px;
  background-color: #51be9f;
  width: 300px;
  height: 300px;
}
.img_div {
  position: absolute;
  top: 80px;
  right: 65px;
}

#i_body2 {
  color: #666;
  background-color: #fff;
  width: 1100px;
  margin: 0 auto 0;
  position: relative;
}

#body2_text_div {
  padding: 50px;
  font-size: 16px;
  line-height: 35px;
  float: right;
  text-align: justify;
  padding-top: 10px;
  padding-right: 53px;
  letter-spacing: 3px;
  font-family: "sans-serif", "Microsoft JhengHei";
}
.color2_div {
  position: absolute;
  top: 55px;
  left: 0px;
  background-color: #51be9f;
  width: 300px;
  height: 360px;
}
.img2_div {
  position: absolute;
  top: 73px;
  left: 20px;
}
.img2_div img {
  width: 489px;
}
.clear_r {
  clear: right;
  height: 0px;
}
.clear_l {
  clear: left;
  height: 30px;
}
#botton_div {
  width: 1080px;
  margin: 0 auto 0;
}
.fb_button {
  float: left;
  width: 215px;
  padding: 5px 15px 5px 15px;
  text-align: center;
  border-radius: 39px;
  height: 70px;

  background-color: #6d9dcd;
  margin: 15px;

  letter-spacing: 1px;
}
.fb_button:hover {
  background-color: #697077;
}

.fb_button:nth-child(1) {
  background-color: #6d9dcd;
}

.fb_button div:nth-child(1) {
  font-size: 15px;
  color: #fff;
  margin-top: 7px;
}
.fb_button div:nth-child(2) {
  font-size: 14px;
  color: #fff;
  margin-top: 1px;
  letter-spacing: 5px;
}

.fb_button:nth-child(1):hover {
  background-color: #797372;
}

@media (max-width: 1280px) {
  body,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  .title_text {
    width: 90%;
  }
  main {
    width: 90%;
  }
  #i_body {
    width: 100%;
    position: unset;
  }
  #body_text_div {
    width: calc(100% - 130px);
    height: 100%;
  }
  #body_text2_div {
    width: calc(100% - 130px);
    height: 100%;
  }

  #body_img_div {
    position: relative;
    height: 679px;
    float: unset;
  }
  #i_body2 {
    width: 100%;
  }
  #body2_img_div {
    position: relative;
    height: 679px;
    float: unset;
  }
  .color2_div {
    left: 20px;
  }
  .img2_div {
    left: 40px;
  }
  #botton_div {
    width: 100%;
  }
  .fb_button {
    float: unset;
    width: 100%;
    padding: 15px 0;
    margin: 15px 0;
    border-radius: 0;
  }
}
@media (max-width: 580px) {
  .color2_div {
    left: 0px;
  }
  .img2_div {
    left: 20px;
  }
}
</style>
