import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/Home.vue'

const routes = [
  
  { path: '/index.html', redirect: '/' },
  { path: '/index/index.html', redirect: '/' },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  { path: '/about/index.html', redirect: '/about' },
  {
    path: '/about',
    name: 'about',

    component: () => import('../components/MyAbout.vue')
  },
  { path: '/news/index.html', redirect: '/news' },
  {
    path: '/news',
    name: 'news',
    component: () => import('../components/MyNews.vue')
  },
  { path: '/farm/index.html', redirect: '/farm' },
  {
    path: '/farm',
    name: 'farm',
    component: () => import('../components/MyFarm.vue')
  },
  { path: '/hospital/index.html', redirect: '/hospital' },
  {
    path: '/hospital',
    name: 'hospital',
    component: () => import('../components/MyHospital.vue')
  },
  { path: '/help/index.html', redirect: '/help' },
  {
    path: '/help',
    name: 'help',
    component: () => import('../components/MyHelp.vue')
  },
  { path: '/donate/index.html', redirect: '/donate' },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('../components/MyDonate.vue')
  },
  { path: '/404', 
  component: () => import('../components/MyFour404.vue') ,
  hidden: true
}
  ,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  }
]

const router = createRouter({
  
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
