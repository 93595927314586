<template>
  <!--導覽列-->
  <div id="banner">
    <a href="../help/help.html"
      ><div id="banner_bg"><img src="../style/banner_bg_1920.png" /></div
    ></a>
    <div id="banner_img" class="flexslider">
      <!--						<img src="./images/banner_1920.jpg" />-->
      <ul class="slides">
        <li
          style="
            width: 100%;
            float: left;
            margin-right: -100%;
            position: relative;
            opacity: 0;
            display: block;
            z-index: 1;
          "
          class=""
          data-thumb-alt=""
        >
          <a
            href="https://crazycat.neticrm.tw/civicrm/contribute/transact?reset=1&id=1"
            target="_blank"
            ><img src="../style/banner_1920.jpg" draggable="false"
          /></a>
        </li>
      </ul>
      <ol class="flex-control-nav flex-control-paging">
        <li><a href="#" class="">1</a></li>
        <li><a href="#" class="flex-active">2</a></li>
        <li><a href="#" class="">3</a></li>
      </ol>
    </div>
  </div>
  <main>
    <div class="act">
      <div class="main_title">
        <img class="title_icon" src="../style/public_cat.png" />
        <img class="title_icon1" src="../style/public_marks02.png" />
        <div class="title_div">有些事不能等</div>
        <img class="title_icon1" src="../style/public_marks02b.png" />
      </div>
      <div class="act_div">
        <a href="https://www.facebook.com/CrazyCatNPV/" target="_blank"
          ><div class="act_icon">
            <div>
              <img src="../style/caticon_004.png" />
            </div></div
        ></a>
        <div class="act_icon">
          <div>
            <a href="/help"><img src="../style/caticon_002.png" /></a>
          </div>
        </div>
        <div class="act_icon">
          <div>
            <a href="/donate"><img src="../style/caticon_003.png" alt="感謝有你" /></a>
          </div>
        </div>
        <a href="ReviewForm.docx" target="_blank"
          ><div class="act_icon">
            <div>
              <img src="../style/caticon_001.png" />
            </div></div
        ></a>
        <div class="clear">&nbsp;</div>
      </div>
    </div>
    <div class="tnr">
      <div class="main_title">
        <img class="title_icon" src="../style/public_cat.png" />
        <img class="title_icon1" src="../style/public_marks01.png" />
        <div class="title_div">消息公告</div>
        <img class="title_icon1" src="../style/public_marks01b.png" />
      </div>
      <div>
        <div
          class="fb-page"
          data-href="https://www.facebook.com/crazycatcharity2"
          data-tabs="timeline"
          data-width="500"
          data-height="500"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/crazycatcharity2"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/crazycatcharity2">貓咪也瘋狂公益協會</a>
          </blockquote>
        </div>
      </div>
    </div>

    <div class="tnr">
      <div class="main_title">
        <img class="title_icon" src="../style/public_cat.png" />
        <img class="title_icon1" src="../style/public_marks02.png" />
        <div class="title_div">貓咪商品義賣</div>
        <img class="title_icon1" src="../style/public_marks02b.png" />
        <div
          class="title_more title_more_2"
          onmouseover='style="cursor: pointer;"'
          onclick="window.open('https://crazycat.waca.ec/','_blank')"
        >
          more
        </div>
      </div>
      <div class="cat_div">
        <div class="cat_pic">
          <a href="https://crazycat.waca.ec/" target="_blank">
            <img src="../style/item_adopt01.jpg"
          /></a>

          <div>
            <div><center>捐款送福袋A</center></div>
          </div>
        </div>
        <div class="cat_pic">
          <a href="https://crazycat.waca.ec/" target="_blank">
            <img src="../style/item_adopt02.jpg"
          /></a>

          <div>
            <div><center>捐款送福袋B</center></div>
          </div>
        </div>
        <div class="cat_pic">
          <a href="https://crazycat.waca.ec/" target="_blank">
            <img src="../style/item_adopt03.jpg"
          /></a>

          <div>
            <div><center>捐款送福袋C</center></div>
          </div>
        </div>
        <div class="cat_pic">
          <a href="https://crazycat.waca.ec/" target="_blank">
            <img src="../style/item_adopt04.jpg"
          /></a>

          <div>
            <div><center>捐款送福袋D</center></div>
          </div>
        </div>
      </div>
    </div>
    <div class="act">
      <div class="main_title">
        <div>
          <img class="title_icon" src="../style/public_cat.png" />
          <a href="https://crazycat-donate.my.canva.site/" target="_blank"
            ><img class="title_icon1" src="../style/public_marks01.png"
          /></a>
          <div class="title_div">TNR 快閃認養</div>
          <a href="https://crazycat-donate.my.canva.site/" target="_blank"
            ><img class="title_icon1" src="../style/public_marks01b.png"
          /></a>
          <a href="https://crazycat-donate.my.canva.site/" target="_blank"
            ><div class="title_more">more</div></a
          >
        </div>
      </div>
      <div class="cat_div">
        <div class="cat_pic">
          <a href="https://crazycat-donate.my.canva.site/" target="_blank">
            <img src="../style/item_below01.jpg"
          /></a>
          <div class="dtime">
            <div>助養貓咪</div>
            <div>100元不嫌少</div>
          </div>
          <div class="cat_text">
            <div></div>
            <div style="width: 100px"></div>
            <div class="clear"></div>
          </div>
        </div>
        <div class="cat_pic">
          <a href="https://crazycat-donate.my.canva.site/" target="_blank">
            <img src="../style/item_below02.jpg"
          /></a>
          <div class="dtime">
            <div>助養貓咪</div>
            <div>100元不嫌少</div>
          </div>
          <div class="cat_text">
            <div></div>
            <div style="width: 100px"></div>
          </div>
        </div>
        <div class="cat_pic">
          <a href="https://crazycat-donate.my.canva.site/" target="_blank">
            <img src="../style/item_below03.jpg"
          /></a>
          <div class="dtime">
            <div>助養貓咪</div>
            <div>100元不嫌少</div>
          </div>
          <div class="cat_text">
            <div></div>
            <div style="width: 100px"></div>
          </div>
        </div>
        <div class="cat_pic">
          <a href="https://crazycat-donate.my.canva.site/" target="_blank">
            <img src="../style/item_below04.jpg"
          /></a>
          <div class="dtime">
            <div>助養貓咪</div>
            <div>100元不嫌少</div>
          </div>
          <div class="cat_text">
            <div></div>
            <div style="width: 100px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear">&nbsp;</div>
  </main>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
